<template>
    <div>
        <top-bar-page :title="$route.meta.title" has-back></top-bar-page>
        <div class="container">
            <van-pull-refresh
                    v-model="isRefreshing"
                    success-text="刷新成功"
                    @refresh="onRefresh"
            >
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onFishing">

                    <div class="list-item" v-for="item in list" :key="item.id">
                        <div style="flex-grow: 1">
                            <div class="font-size-15">{{item.amountAvl}}</div>
                            <div class="font-size-15"  style="margin-top: 2rem;">{{item.createDate}}</div>
                        </div>
                        <div class="alignRight">
                            <div class="font-size-15" style="color:darkblue" v-if="item.status === 0" @click="cancelOrder(item.id)">撤回</div>
                            <div class="font-size-15" v-if="item.status === 1">已完成</div>
                            <div class="font-size-15" v-if="item.status === 2">已撤回</div>
                            <div class="font-size-15" style="margin-top: 2rem;">{{item.price + "/ITG"}}</div>
                        </div>
                    </div>

                </van-list>
            </van-pull-refresh>
        </div>

    </div>
</template>

<script>
    import TopBarPage from "../../components/TopBarPage";
    import {List, PullRefresh, Toast, Dialog} from "vant"

    export default {
        name: "FishingRecord",
        components: {
            TopBarPage,
            [List.name]: List,
            [PullRefresh.name]: PullRefresh,
            [Dialog.Component.name]: Dialog.Component
        }, data() {
            return {
                list: [],
                isRefreshing: false,
                loading: false,
                finished: false,
                page: 1,
            }
        },
        methods: {
            onRefresh() {
                this.page = 1;
                this.finished = false;
                // this.onFishing();//下拉刷新会触发触底加载
            },
            cancelOrder(id){
                Dialog.confirm({
                    message: '确定要撤销订单吗'
                }).then(() => {
                    this.$api.user.transferCancel({
                        orderId: id,
                        userId: this.$utils.getUserId()
                    }).then(res => {
                        Toast.success(res.message);
                        /** 刷新列表 */
                        location.reload();
                    }).catch(() => {

                    });

                });

            },
            onFishing() {
                this.$api.user.fishing({
                    pageNum: this.page,
                    pageSize: 10,
                    userId: this.$utils.getUserId()
                }).then(res => {
                    if (this.page === 1) {
                        this.list = [];
                        this.list = res.result;
                        //下拉刷新状态
                        this.isRefreshing = false;
                        // 加载状态结束
                        this.loading = false;
                        if (this.list.length < 10) {
                            // 数据全部加载完成
                            this.finished = true;
                        }
                    } else {
                        if (res.result.length == 0) {
                            // 数据全部加载完成
                            this.finished = true;
                        } else {
                            for (let i = 0; i < res.result.length; i++) {
                                this.list.push(res.result[i]);
                            }
                            // 加载状态结束
                            this.loading = false;
                        }
                    }
                    this.page++;

                }).catch(() => {

                });
            }
        }
    }
</script>

<style scoped>
    .container {
        padding-top: 5.2rem;
    }

    .list-item {
        height: 5rem;
        background-color: white;
        display: flex;
        align-items: center;
        padding: 0 1.5rem;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }
    .alignRight{
        text-align: right;
    }
</style>
