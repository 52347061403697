<template>
    <div>
        <top-bar-page :title="$route.meta.title" has-back></top-bar-page>
        <div style="height:0.5rem;background-color: #EAEAEA;margin-top: 5rem">
        </div>
        <div class="container">
            <div class="info-item">
                <span class="font-size-15 flex-grow color-333">当前市值</span>
                <span class="font-size-15">{{marketValue}}</span>
            </div>
            <div class="info-item">
                <span class="font-size-15 flex-grow color-333">可认购数量</span>
                <span class="font-size-15">{{enableBuyAmount}}</span>
            </div>
            <div class="info-item">
                <span class="font-size-15 flex-grow color-333">剩余可认购数量</span>
                <span class="font-size-15">{{surplusBuyAmount}}</span>
            </div>
        </div>
        <div style="height:0.5rem;background-color: #EAEAEA">
        </div>
        <div style="height:6rem;margin-left: 1.5rem;margin-top: 1rem">
            <span class="font-size-15 flex-grow color-333">数量</span>
            <div style="height: 4rem;margin-top: 1rem;margin-bottom: 0.1rem">
                <input v-model="amount" type="number" style="text-align:left;font-size:15px;background-color:white" placeholder="请输入认购数量">
            </div>
            <div style="height:0.02rem;background-color: #EAEAEA">
            </div>
        </div>
        <div style="height:4rem;margin-left: 1.5rem;margin-top: 2rem">
            <span class="font-size-15 flex-grow color-333">年限</span>
            <div class="flex-between" @click="selectYear(1)" style="margin-top: 2rem;margin-right: 1.5rem">
                <span class="font-size-15 flex-grow color-333">1年</span>
                <img :src="select1" id="select1" style="width: 2rem;height: 2rem" alt="">
            </div>
            <div class="flex-between" @click="selectYear(2)" style="margin-top: 2rem;margin-right: 1.5rem">
                <span class="font-size-15 flex-grow color-333">2年</span>
                <img :src="select2" id="select2" style="width: 2rem;height: 2rem" alt="">
            </div>
            <div class="flex-between" @click="selectYear(3)" style="margin-top: 2rem;margin-right: 1.5rem">
                <span class="font-size-15 flex-grow color-333">3年</span>
                <img :src="select3" id="select3" style="width: 2rem;height: 2rem" alt="">
            </div>
        </div>

        <van-popup v-model="showPayPasswordDialog" position="bottom" style="height: 24rem" :lazy-render="false"
                   :round="false">
            <div style="margin:2rem 1.5rem;">
                <div class="flex-between">
                    <span class="font-size-15 font-weight-bold">{{title}}</span>
                    <img src="../../assets/images/close.png" style="width: 2rem;height: 2rem" alt=""
                         @click="showPayPasswordDialog=false">
                </div>

                <div style="height: 5rem;margin-top: 3rem;margin-bottom: 3rem">
                    <input v-model="payPassword" type="number" placeholder="输入交易密码">
                </div>

                <van-button type="info" size="large" @click="setPayPassword">确定</van-button>
            </div>
        </van-popup>
        <div style="height:3rem;position: fixed;bottom: 3rem;width:100%">
            <van-button type="info" size="large" style="" @click="buyItg">认购</van-button>
        </div>
    </div>
</template>

<script>
    import TopBarPage from "../../components/TopBarPage";
    import {Popup, Button, ActionSheet, Toast} from "vant";
    import image1 from '../../assets/images/icon_xuanzhong.png'
    import image2 from '../../assets/images/weixuanding_b.png'

    export default {
        name: "PersonInfo",
        components: {
            TopBarPage,
            [Popup.name]: Popup,
            [Button.name]: Button,
            [ActionSheet.name]: ActionSheet
        },
        data() {
            return {
                enableBuyAmount: "",
                marketValue: "",
                surplusBuyAmount: "",
                amount: "",
                years: "1",
                select1: image1,
                select2: image2,
                select3: image2,
                hasPassword: false,
                showPayPasswordDialog: false,
                payPassword: ''
            }
        },
        mounted() {
            this.getAmount();
        },
        methods: {
            selectYear (index){
                this.years=index;
                if(index === 1){
                    this.select1 = image1;
                    this.select2 = image2;
                    this.select3 = image2;
                }else if(index === 2){
                    this.select2 = image1;
                    this.select1 = image2;
                    this.select3 = image2;
                }else{
                    this.select3 = image1;
                    this.select2 = image2;
                    this.select1 = image2;
                }
            },
            getAmount() {
                this.$api.user.getPoolAmount({userId: this.$utils.getUserId()}).then(res => {
                    this.enableBuyAmount = res.result.enableBuyAmount;
                    this.marketValue = res.result.marketValue;
                    this.surplusBuyAmount = res.result.surplusBuyAmount;
                }).catch(() => {
                });
            },
            buyItg() {
                // this.showPayPasswordDialog = true;
                if(this.amount == null){
                    Toast.success("请输入认购金额");
                    return;
                }
                if(this.years == null){
                    Toast.success("请选择认购年限");
                    return;
                }
                this.$api.user.buyPoolAliPay({userId: this.$utils.getUserId(), amount: this.amount, orderType: this.years}).then(res => {
                    Toast.success(res.message);
                    this.$callApp("ALIPAY_ORDER", res.result, (res) => {
                        console.log(res);
                        if (res.resultStatus == 9000) {
                            console.log("成功");
                            this.$callApp("OPEN_ORDER_AT_INDEX", 0, () => {});
                        } else {
                            console.log("失败");
                        }
                    });
                }).catch(() => {
                });
            },
            setPayPassword() {
                if(this.payPassword === '' || this.payPassword.length !== 6){
                    Toast.success("请输入6位交易密码");
                    return;
                }
                if(this.amount == null){
                    Toast.success("请输入认购金额");
                    return;
                }
                this.$api.user.buyPoolAmount({userId: this.$utils.getUserId(), amount: this.amount, price: this.price,payPassword: this.payPassword}).then(res => {
                    Toast.success(res.message);
                }).catch(() => {
                });
                this.showPayPasswordDialog = false;
            }
        }
    }
</script>

<style scoped>
    .container {
        padding-top: 0.5rem;
        margin: 0 1.5rem;

    }

    .info-item {
        background-color: white;
        height: 5rem;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }

    input {
        border: none;
        outline: none;
        resize: none;
        font-size: 16px;
        height: 100%;
        width: 100%;
        background-color: #FAFAF7;
        text-align: center;
    }

</style>
