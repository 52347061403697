<template>
    <div class="top-bar" :class="[ !hasBack ? 'center' : 'between',{bg_color:hasBgColor}]">
        <img src="../assets/images/zuobian.png" v-if="hasBack" style="width: 1.8rem;height: 1.5rem" @click="goBack"/>
        <span class="font-size-18">{{title}}</span>
        <span class="font-size-15" v-if="hasRight" @click="onClickRight">{{rightText}}</span>
        <div v-if="!hasRight"></div>
    </div>
</template>

<script>
    import {Icon} from "vant";

    export default {
        name: "TopBarPage",
        props: {
            title: {type: String, default: ''},
            rightText: {type: String, default: ''},
            hasBack: {type: Boolean, default: false},
            hasRight: {type: Boolean, default: false},
            hasBgColor: {type: Boolean, default: true},

        },
        components: {
            [Icon.name]: Icon
        },
        methods: {
            goBack() {
                this.$router.back();
            },
            onClickRight() {
                this.$emit("onClickRight");
            }
        }
    }
</script>

<style scoped>
    .top-bar {
        display: flex;
        align-items: center;
        height: 5rem;
        padding: 1.5rem;
        position: fixed;
        left: 0;
        z-index: 1;
        top: 0;
        font-size: 1.8rem;
        right: 0;
        box-sizing: border-box;

    }

    .center {
        justify-content: center;
    }

    .bg_color {
        background-color: #FAFAFA;
    }

    .between {
        justify-content: space-between;
    }
</style>
