<template>
    <div>
        <top-bar-page :title="$route.meta.title" has-back></top-bar-page>

        <div class="container">
            <div class="info-item" @click="showHeadSelectDialog=true">
                <span class="font-size-15 flex-grow color-999">头像</span>
                <img style="width: 4.8rem;height: 4.8rem;border-radius: 4.8rem;margin-right: 1.5rem"
                     :src="photo" alt="">
                <img class="img-right" src="../../assets/images/youbian.png" alt="">
            </div>
            <div class="info-item" @click="showSetNicknameDialog=true">
                <span class="font-size-15 flex-grow color-999">昵称</span>
                <span class="margin-right-15 font-size-15">{{nickname}}</span>
                <img class="img-right" src="../../assets/images/youbian.png" alt="">
            </div>
        </div>

        <van-popup v-model="showSetNicknameDialog" position="bottom" style="height: 34rem" :lazy-render="false"
                   :round="false">
            <div style="margin:2rem 1.5rem;">
                <div class="flex-between">
                    <span class="font-size-15 font-weight-bold">修改昵称</span>
                    <img src="../../assets/images/close.png" style="width: 2rem;height: 2rem" alt=""
                         @click="showSetNicknameDialog=false">
                </div>

                <div style="height: 5rem;margin-top: 6rem;margin-bottom: 6rem">
                    <input v-model="newNickname" placeholder="输入新昵称">
                </div>

                <van-button type="info" size="large" @click="setNickname">确定修改</van-button>
            </div>
        </van-popup>
        <van-action-sheet
                v-model="showHeadSelectDialog"
                :actions="actions"
                cancel-text="取消"
                close-on-click-action
                :round=false
                @select="onSelect"/>
    </div>
</template>

<script>
    import TopBarPage from "../../components/TopBarPage";
    import {Popup, Button, ActionSheet, Toast} from "vant"

    export default {
        name: "PersonInfo",
        components: {
            TopBarPage,
            [Popup.name]: Popup,
            [Button.name]: Button,
            [ActionSheet.name]: ActionSheet
        },
        data() {
            return {
                nickname: "",
                photo: "",
                showSetNicknameDialog: false,
                newNickname: '',
                showHeadSelectDialog: false,
                actions: [{name: '拍照'}, {name: '相册选择'}],
            }
        },
        mounted() {
            this.nickname = this.$route.params.nickname;
            this.photo = this.$route.params.photo;
        },
        methods: {
            onSelect(e, index) {
                console.log(e)
                console.log(index)
                this.$bridge.callNaiveApp("Geek_Camera", index, (base64) => {
                    // app回传回来的值{'code':0,'msg':'dfsdfsdfsdfsdfdsfd'}
                    this.imageUpload(base64.msg);
                })
            },
            setNickname() {
                this.$api.user.changNickName({userId: this.$utils.getUserId(), newName: this.newNickname}).then(res => {
                    Toast.success(res.message);
                    this.nickname = res.result.geekNickName;
                    this.showSetNicknameDialog = false;
                }).catch(() => {
                });
            },
            imageUpload(imgBase64) {
                this.$api.user.imageUpload({
                    userId: this.$utils.getUserId(),
                    imgType: 1,
                    imgBase64: imgBase64
                }).then(res => {
                    Toast.success(res.message);
                    this.photo = res.result;
                }).catch(() => {
                });
            }
        }
    }
</script>

<style scoped>
    .container {
        padding-top: 6.2rem;
        margin: 0 1.5rem;

    }

    .info-item {
        background-color: white;
        height: 8.3rem;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        padding: 0 1.5rem;
    }

    input {
        border: none;
        outline: none;
        resize: none;
        font-size: 16px;
        height: 100%;
        width: 100%;
        background-color: #FAFAF7;
        text-align: center;
    }
</style>
